import React from "react";
import ReactPlayer from "react-player";
import "./programs.css";

const Programs = () => {
  return (
    <section>
      <div className="goc__programs  ">
        <h1>
          Welcome To <span className="gradient__text"> Guild Of Coders</span>
        </h1>
        <div>
          <p>
            The best way to become a Software Engineer is through a combination
            of building technical skills, coaching assisted project work, and
            technical interview prep. Guild of Coders makes sure you know how to
            do the job of a Software Engineer and also the skills to directly
            get job offers.
          </p>
        </div>
      </div>
      <div className="goc__video-container">
      <ReactPlayer
          url="https://www.youtube.com/watch?v=CUb9T8IYERU"
          className="video" controls/>
      </div>
      <div className="space"></div>
      <div className="goc__programs-comprehensive">
        <h2>
          Comprehensive 
           <span className="gradient__text"> Courses Augmented</span> With Coaching
          from <span className="gradient__text">Industry Professionals</span>
        </h2>
        <p>
          Learn Python, SQL, and Data Structures Gain Real World Experience
          working on Projects Ace the Techical Interview.
        </p>
      </div>
    </section>
  );
};

export default Programs;
