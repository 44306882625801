import React, {useState} from 'react';
import './navbar.css';
import { RiMenu3Line, RiCloseLine} from 'react-icons/ri'
import logo from '../../assets/logo.png';
import { Link } from 'react-router-dom';


const Menu = () => (
  <>
  <p> <Link to='/'>Home</Link></p>
  <p > <Link to='/course'>Tech Accelarator</Link></p>
  {/* <p a href='#appointments'>Appointments</p>
  <p a href='#contact'>Contact</p> */}
  </>
)

const NavBar = () => {
  const[toggleMenu, setToggleMenu] = useState(false);
 
  return (
    <div className="goc__navbar">
      <div className="goc__navbar-links">
        <div className="goc__navbar-links_logo ">
          <img src={logo} alt="logo" />
        </div>
      </div>
      <div className="goc__navbar-links_container">
         <Menu />
        {/* <div className="goc__navbar-sign">
          <button type='button'>Sign Up</button>
        </div> */}
        </div>
        <div className="goc__navbar-menu"> 
        {toggleMenu 
        ? <RiCloseLine color='#fff' size={27} onClick={() => setToggleMenu(false) } />
        : <RiMenu3Line color='#fff' size={27} onClick={() => setToggleMenu(true) } />
        }
        {toggleMenu && (
          <div className="goc__navbar-menu_container scale-up center">
            <div className="goc__navbar-menu_container-links">
            <Menu />
            <div className="goc__navbar-menu_container-links-sign">
          <button type='button'> <a href="https://techaccelerator.as.me/schedule.php?fbclid=IwAR1H5eLAH5FBWfgUj4pD0e2RMb9-yVGSAOjTNlFL8Z_ER8Vv0sPZcUy8CGQ">Sign Up</a> </button>
        </div>
            </div>
          </div>
        )}
        </div>
    </div>
  )
}

export default NavBar;