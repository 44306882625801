import React from 'react';
import ReactDom from 'react-dom';

import App from './App';
import './index.css'
import { createHashRouter, RouterProvider} from 'react-router-dom';
import { CoursePage, QuizPage } from './components';

const router = createHashRouter([
    {
        path: "/",
        element: <App />
    },

    {
        path: "/course",
        element: <CoursePage />
    },

    {
        path: "/results",
        element: <QuizPage/>
    }
])

const root = ReactDom.createRoot( document.getElementById('root'));
root.render(
    <RouterProvider  router={router}/>
)