import React, { useState } from "react";
import './question.css'

const dataCollection = [
  {
    question: "How does the Tech Accelerator Program work?",
    answer:
      "The tech accelerator program that consists of 3 phases, 1.Work at your own pace for courses with a suggested pace in order to build skills, 2. Work on creating your projects using your newly learned skills, 3. Learn How to properly interview and job search",
  },

  {
    question: "How long is the Tech Accelerator Program?",
    answer:
      "The Tech Accelerator Program is a 6 month program but students are allowed to take breaks or work at their own pace.  Finishing faster is highly encouraged and finishing slower is possible.",
  },
  {
    question: "What does the Tech Accelerator Program Give Me?",
    answer:
      "The Tech Accelerator primary goals is to give you the technical skills to do the job as a Software Engineer, the project work to give you experience and credibility to get interviews, and the ability to ace the technical interviews to get you the job offers.  So far, this is the only program that works with complete novices with the goal of getting you past the finish line.",
  },
  {
    question: "When does the next Tech Accelerator class start?",
    answer:
      "The new Tech Accelerator pod starts at the beginning of every month, if you take a break from the program long enough you’ll be moved to a later pod.",
  },
  {
    question: "How many Mock Interviews do I get with Tech Accelerator?",
    answer:
      "The program comes with  4 interviews.  You can get more interviews by purchasing an interview package at cost.",
  },
  {
    question: "Do I need to know how to code to become a student?",
    answer:
      "No, you do not need to know how to code.  In fact, my program is one of the few programs to specifically work with students who have no knowledge of coding.  Most programs like boot camps require knowledge of coding before starting the program and will not take you on as a student until you pass their coding interviews",
  },
  {
    question: "Can I actually get a Software Engineering Job without a degree?",
    answer:
      "Yes, you can join the top tech companies without a Computer Science degree.  If you can show you have prior working experience and some form of education then you can get interviews..  Your ability to ace the interview will prove to them you can do the job.  Many of my fellow engineers and coworkers had no degrees.  I even met a Software Engineer who had no high school diploma.  Coding Bootcamps graduates are also further evidence that you don’t need a traditional university degree to get interviews.  So, yes it is possible and students have done it and it's been done countless times before.",
  },
  {
    question: "How much money can I get after I graduate?",
    answer:
      "Entry Level Software Engineers can make 80k-100k as college graduates working remotely.  Your job offers salaries will depend on multiple factors including your geographical location, skillset, background, and any prior experience.",
  },
  {
    question:
      "How long does it take for me to get a job as a Software Engineer?",
    answer:
      "On average, to receive your first offer it takes on average 3 months.  Because the program is 6 months long, you should on average receive your first offer as a Software Engineer in 9 months on average.",
  },
  {
    question: "How many hours do I need to devote to the program?",
    answer:
      "On average it takes 10 hours per week to complete the program in 6 months.  This might vary depending on the projects you choose to take on and the pace you work at.",
  },
  {
    question: "How do I know that I’m ready for the program?",
    answer:
      "During our interview and call with you, we’ll get a look at your background and situation to see if you're a good fit for our program.  Traits like prior education, free time, and motivation are aspects we will delve into to make sure you can be successful in this program.  We would rather not take your money if you know you cannot be a successful student.",
  },
  {
    question: "Why should I take this program and not some other program?",
    answer:
      " 1.Flexibility - We can work with you if you have work, family, or need to take a break. You do not have to quit your job during the program and classes are work at your own pace., 2.Willing to work with students with no prior skills in coding and technical ability, 3. Unlike completely online programs, you are assigned a Industry Mentor who has worked at top tech companies like Google, Meta, Apple, Amazon.  We will not only be a resource for your questions but we will motivate you to continue working and keeping up the pace of learning.  We will hold you accountable if you fail to put in the work and provide feedback.",
  },
];

const Question = () => {
  const [questions, setActiveQuestions] = useState(-1);

  function toggleQuestion(index) {
    if (index === questions) {
      setActiveQuestions(-1);
      return;
    }
    setActiveQuestions(index);
  }

  return (
    <section>
      <div className="goc__container">
        <div>
          <h1 className="question__title gradient__text">
            Frequently Asked Questions
          </h1>
        </div>
        <div className="goc__question-faq">
          {dataCollection.map((item, index) => (
            <div key={index} onClick={() => toggleQuestion(index)}>
              <div className="goc__question-faq-heading">
                <h3 className={questions === index ? "active" : ""}>
                  {item.question}
                </h3>
              </div>
              {questions === index ? (
                <>
                  <span className="verticle"> - </span>
                </>
              ) : (
                <>
                  <span className="horizental"> + </span>
                </>
              )}
              <div>
                <p className={questions === index ? "active" : "inactive"}>
                  {item.answer}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      </section>
  );
};

export default Question;
