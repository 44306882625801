import React from "react";
import "./thang.css";
 


const Thang = () => {

  return (

    <div className="goc__thang section__padding" id="thang">
      <div className="goc__thang-container ">
        <h1>Meet your Coach:</h1>
        <h1 className="gradient__text jack">Jack Tran</h1>
      </div>
      <div className="goc__thang-content">
        <p>
          My name is Jack Tran, founder & CEO of Guild of Coders and head of a
          community of tech industry mentors and students who desire to develop
          their skills and learn the tools necessary to secure lucrative
          employment opportunities in software development.</p>
          
          <p >My family and I grew
          up in a poor refugee family escaping Communism Vietnam. As a kid I
          worked making a few dollars a day as a farm worker picking
          raspberries. My first real job was in the US Navy where I cleaned
          toilets and urinals while hoping to finish tech school to be a Nuclear
          EM3. I started to learn how to code in Mechanical Engineering courses
          learning Matlab and decided to change majors to Computer Science when
          I noticed I enjoyed coding and helping others learn how to code.</p>
          
          <p>After
          graduating from University and a bootcamp, I started working in Tech.
          After learning my teammates with only 1 year of experience were making
          $60k more than me, I decided to jump hop every 2 years and made it my
          life goal to optimize my career earnings. I was able to grow my income
          from $100k to over $230k and received more than 20 job offers in the
          span of 7 years.</p>  
          
          <p>I’ve helped colleagues and friends get job offers
          from top tech companies and I'd like to do the same for you — I want
          you to become a Software Engineer, learn to code with confidence, and
          pursue the high-paying remote career you've always wanted. See you
          inside!
          </p>
      </div>
    </div>

  );
};

export default Thang;
