import React from "react";
import "./quizpage.css";
import { Footer, NavBar, Question, Test } from "..";
import ReactPlayer from "react-player";

const QuizPage = () => {
  return (
    <>
      <NavBar />
      <section>
        <div className="goc__quiz-title-container">
          <div className="goc__quiz-title">
            <h3 className="gradient__text">THE RESULTS ARE IN...</h3>
          </div>
          <div className="goc__quiz-title-h2">
            <h1>Congratulations!</h1>
            <h2>You’ve been accepted into the</h2>
            <div className="goc__quiz-title-2">
              <h2>
                <span className="gradient__text"> Tech Accelerator </span>
              </h2>
            </div>
          </div>
          <p className="quiz-sky">
            Based on your answers to the eligibility quiz, you are a fantastic
            candidate. Hopefully, the quiz provided you with the confidence to
            take your career into your own hands with the help of the Tech
            Accelerator Program.
            <br /> Now is the time to choose the perfect program for you, and
            book a call to continue your application.
          </p>
        </div>
        <div className="goc__video-container">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=Fkp0mmm_iqo"
            className="video"
            controls
          />
        </div>

        <div className="goc__body-container">
          <div className="goc__body-title">
            <h2>
              In Just Six Months You Could Be Interviewing for{" "}
              <span className="gradient__text">
                Remote Software Engineering{" "}
              </span>
              jobs Earning Over $90k Per Year <br />
              It's true — what you learn in the{" "}
              <span className="gradient__text"> Tech Accelerator</span> Program
              in only six months puts so many courses and boot camps to shame!
            </h2>
          </div>
          <div className="goc__body-info">
            <p>
              It’s years of experience condensed into months. You invest now so
              you can start earning a higher salary ASAP.
            </p>
            <br />
            <p>
              There are only 10 spots available in the Tech Acclerator Program.
              Our students study and thrive in small cohorts, since our Mentors
              give personal attention to every student to make sure they
              succeed.
            </p>
            <br />
            <p>
              In order to secure your spot, and gain access to our custom
              platform, real-world team project building, mentoring, career
              support, and so much more, you'll need to act now, before the next
              cohort starts.
            </p>
            <br />
            <p>Don’t delay your dream life for another three or more months.</p>
          </div>
          <div className="goc__summary-title">
            <h2>Brief Summary of How It Works</h2>
          </div>
          <div className="goc__summary-info">
            <p>
              It’s years of experience condensed into months. You invest now so
              you can start earning a higher salary ASAP.
            </p>
            <br />
            <p>
              There are only 10 spots available in the Tech Acclerator Program.
              Our students study and thrive in small cohorts, since our Mentors
              give personal attention to every student to make sure they
              succeed.
            </p>
            <br />
            <p>
              In order to secure your spot, and gain access to our custom
              platform, real-world team project building, mentoring, career
              support, and so much more, you'll need to act now, before the next
              cohort starts.
            </p>
          </div>
        </div>
      </section>
      <Test />
      <section>
        <div className="goc__footer-container">
          <div className="goc__summary-title">
            <h2>
              What is <span className="gradient__text">stopping you </span> from
              having <br /> the life you desire?
            </h2>
            <div>
              <h3>“I can learn all this stuff from YouTube videos”</h3>
            </div>
          </div>
          <div className="goc__summary-info">
            <p>
              Well…technically, you might be able to, but the odds of being
              ready for your dream job in 3-6 months are close to zero.
            </p>
            <br />
            <p>
              Even if you’re disciplined, focused and accountable, you will
              still get stuck. You don’t have the experienced mentor's help. You
              won’t have real-work experience developing in a team, or mock
              interviews with expert feedback. You won’t have access to
              best-in-the industry materials designed just for you.
            </p>
            <br />
            <p>
              Think about it this way: In movies, the chosen student always
              finds a wise teacher who helps the student learn in months what
              the teacher took years or even decades to learn.
            </p>
            <br />
            <p>
              Thanks to Tech Accelerator, you can invest now and start earning a
              high-salary in a few months, instead of a few years. You’ll have
              years of making a higher income doing what you love. Your
              investment in yourself will easily return ten or twentyfold.
            </p>
          </div>
        </div>

        <div className="goc__master-container">
          <div className="card-title">
            <div className="quiz-card-title">
              <h2>
                Choose Your
                <span className="gradient__text"> Masterclass Experience</span>
                <h2 className="card-title-h2">and skyrocket your path to a</h2>
                <h2 className="card-title-h2-2">
                  <span className="90k">$90,000/yr career</span>
                </h2>
              </h2>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="card-body-title">
                  <h2>
                    <span className="gradient__text">Tech Accelerator</span>
                  </h2>
                </div>
                <div className="goc__price">
                  <div><h1>
                    <span></span> 
                  </h1></div>
                  <div><p>6-36 month payment plan available</p></div>
                </div>
                <div className="goc__card-info">
                  <p>Perfect for Getting Hired As a Software Engineer</p>
                  <p>6 months of coaching or more</p>
                  <p>Unequaled 2-Month Capstone Project</p>
                  <p>Mock interviews</p>
                  <p>24/7 Access to for Questions</p>
                  <div className="program">
                    {" "}
                    <span className="gradient__text">
                      This program will include!
                    </span>
                  </div>
                  <div className="program-p">
                    <p>Coding Bootcamp</p>
                    <p>Interview Prep Course</p>
                    <p>3 spectacular monthly projects</p>
                    <p>Webinars and Q&A with Jack Tran</p>
                    <p>Portfolio, CV, LinkedIn Review</p>
                    <p>Data Structure and Algorithm</p>
                    <p>24/7 Chat Mentor Assistance</p>
                    <p>Weekly Team Mentoring</p>
                    <p>Weekly 1-on-1 mentoring</p>
                  </div>
                  <div className="card-body-button">
                    <button type="button">
                      <a href="https://techaccelerator.as.me/schedule.php?fbclid=IwAR1H5eLAH5FBWfgUj4pD0e2RMb9-yVGSAOjTNlFL8Z_ER8Vv0sPZcUy8CGQ">
                        Book A Call
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="goc__bonuse-container">
          <div className="goc__bonuse-title">
            <h2>Bonuses:</h2>
          </div>
          <div className="goc__bonuse-info">
            <p>
            Guide on how to work and travel internationally.
            </p>
            <br />
            <p>
            Guide on how to work 2 jobs.
            </p>
            <br />
            <p>
            All Guild of Coders Course Material.
            </p>
            <br />
            <p>
            All Future Guild of Coders Pro Course Material.
            </p>
            <br />
            <p>
            Access to a driven community of like-minded developers.
            </p>
            <br />
            <p>
            Business Masterclass to sell yourself, your Agency or Freelance services.
            </p>
            <br />
            <p>
            Exclusive Career Materials.
            </p>
          </div>
          <div className="bonuse-still">
            <h2>
              Still not sure? Book a call and <br /> we’ll help you make the{" "}
              <span className="gradient__text">right decision!</span>
            </h2>
          </div>
          <div className="bonuse-info-button">
            <button type="button">
              <a href="https://techaccelerator.as.me/schedule.php?fbclid=IwAR1H5eLAH5FBWfgUj4pD0e2RMb9-yVGSAOjTNlFL8Z_ER8Vv0sPZcUy8CGQ">
                Book A Call
              </a>
            </button>
          </div>
        </div>
      <Question />
      <Footer />
      </section>
    </>
  );
};

export default QuizPage;
