import React from "react";
import "./footer.css";


const Footer = () => {
  return (
    <>
    <section>
    <div className="footer">
      <div className="goc__footer section__padding">
        <div className="goc__footer-links">
        </div>
        <div className="goc__footer-links_div">
            <h4 className="gradient__text">Social Media</h4>
            <a href="https://www.facebook.com/groups/460066709381689">
              <p>Facebook</p>
            </a>
            <a href="https://www.instagram.com/jack_tran01/">
              <p>Instagram</p>
            </a>
            <a href="https://www.linkedin.com/in/thangntran/">
              <p>Linkedin</p>
            </a>
            <a href="/">
              <p>Bootcamp</p>
            </a>
          </div>
          <div className="goc__footer-links_div">
            <h4 className="gradient__text">Guild Of Coders</h4>
            <a href="/">
              <p>Our Story</p>
            </a>
            <a href="/">
              <p>Programs</p>
            </a>
            {/* <p> <a href="/results"> Contact Us</a></p> */}
            </div>

          <div className="goc__footer-below">
            <div className="goc__footer-copyright">
              <p>
                @{new Date().getFullYear()} Guild Of Coders. All right reserved.
              </p>
            </div>
            <div className="goc__footer-below-links">
              <a href="/"><p className="gradient__text">Terms & Conditions</p></a>
              <a href="/"><p className="gradient__text">Privacy</p></a>
              <a href="/"><p className="gradient__text">Security</p></a>
              <a href="course"><p className="gradient__text">Cookie Declaration</p></a>
            </div>
          </div>
      </div>
    </div>
    </section>
    </>
  );
};

export default Footer;
