import React from "react";
import {
  NavBar,
  Programs,
  Header,
  Footer,
  Tech,
  Thang,
  Question,
  Test,
} from "./components";
import "./App.css";



const App = () => {
 
  return (
    <>
    <section>
    <div>
      <div className="App">
        <div className="gradient__bg">
          <NavBar />
          <Header />
          <Programs />
          <Tech />
          <Thang />
          <Question />
          <Test />
          <Footer />
        </div>
      </div>
    </div>
    </section>
    </>
  );
};

export default App;
