import React from "react";
import "./test.css";
import Thomas from "../../assets/Thomas R.png";
import Solid from "../../assets/solid-star.png";
import Marcus from "../../assets/Marcus J.png";
import Andy from "../../assets/Andy M.jpg";

const Test = () => {
  return (
    <div className="goc__test-container">
      <div className="wrapper">
      <div>
        <h1 className="test-title">
          The <span className="gradient__text">Success </span> Success of Our
          <span className="gradient__text"> Student's</span>
        </h1>
      </div>
      <div className="goc__test_card-container">
        <div className="test-card">
          <div className="test-card-thumb">
            <img src={Thomas} alt="thomas" className="img" />
            <span className="student-name-tom">
              Thomas R.
              <p>Full-Stack Engineer</p>
            </span>
          </div>
          <div className="test-card-body">
            <p className="review">
              "Jack worked with me to help work on my first projects and gain
              experience before my first job. After that, Jack also helped me
              improve my interviewing skills and gave me my first Software
              Engineering job offer. I attended Hack Reactor but it was Jack
              that helped me get past the finish line to get my first job
              offer."
            </p>
            <div className="rating-tom">
              <img src={Solid} alt="solid star" className="star" />
              <img src={Solid} alt="solid star" className="star" />
              <img src={Solid} alt="solid star" className="star" />
              <img src={Solid} alt="solid star" className="star" />
              <img src={Solid} alt="solid star" className="star" />
              
            </div>
          </div>
        </div>
        <br />

        {/* card 2 */}
        <div className="goc__test-card-container2">
          <div className="test-card2">
            <div className="card-thumb-marcus">
              <img src={Marcus} alt="marcus" className="img" />
              <span className="student-name">
                Marcus J.
                <p>Full-Stack Engineer</p>
              </span>
            </div>
            <div className="test-card-body">
              <p className="review">
                "Jack was an amazing mentor and coach. He worked with me to
                improve my interviewing skills. After doing mock interviews and
                giving feedback to me I was able to significantly improve and
                gain more confidence at technical interviews. This helped me
                land a job at Rakuten, one of the top tech companies in Tokyo,
                Japan. Jack has a passion for helping people get into Tech and
                is willing to do more than he promised in the program to make
                sure you make it past the finish line.""
              </p>
              <div className="rating-star">
                <img src={Solid} alt="solid star" className="star" />
                <img src={Solid} alt="solid star" className="star" />
                <img src={Solid} alt="solid star" className="star" />
                <img src={Solid} alt="solid star" className="star" />
                <img src={Solid} alt="solid star" className="star" />
              </div>
            </div>
          </div>
        </div>
        <br />

        {/* card 3 */}

        <div className="goc__test-card-container2">
          <div className="test-card">
            <div className="card-thumb-marcus">
              <img src={Andy} alt="andy" className="img" />
              <span className="student-name">
                Andy M.
                <p>Full-Stack Engineer</p>
              </span>
            </div>
            <div className="test-card-body">
              <p className="review">
                " Despite graduating from Coding Dojo, I was unable to get
                interviews from companies. Jack worked with me to improve my
                resume, sharpen my technical interviewing skills, and fill any
                gaps that my boot camp left me with. I currently work as a
                remote Software Engineering Freelancer. Giving me the freedom to
                work where and when I want around the world. Jack was always
                responsive and his personalized mentorship and coaching made the
                program worth every penny.""
              </p>
              <div className="rating-star">
                <img src={Solid} alt="solid star" className="star" />
                <img src={Solid} alt="solid star" className="star" />
                <img src={Solid} alt="solid star" className="star" />
                <img src={Solid} alt="solid star" className="star" />
                <img src={Solid} alt="solid star" className="star" />
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Test;
