import React from "react";
import "./coursepage.css";
import { Footer, NavBar, Question, Test } from "..";
import ReactPlayer from "react-player";

const CoursePage = () => {
  return (
    <>
      <div>
        <NavBar />
      </div>
      <section>
        <div className="goc__title-container" id="course">
          <div className="goc__title">
            <h3 className="gradient__text">
              WANT TO LAND YOUR DREAM SOFTWARE ENGINEERING JOB IN 3-6 MONTHS?
              THEN DO THIS…
            </h3>
          </div>
          <div className="goc__title-h2">
            <h1>
              Become a{" "}
              <span className="gradient__text">Software Engineer </span>
              Guaranteed.
            </h1>
          </div>
          <p className="sky">
            Skyrocket your career with our flexible and remote program. Graduate
            in 6 months,
            <br /> land your dream job with support from our mentors, or get
            your money back.
          </p>
          <div className="goc__title-button">
            <button>
              <a href="https://thangtran178782.typeform.com/to/Gns9U4EN">
                Apply Now
              </a>
            </button>
          </div>
        </div>
        <div className="goc__video-container">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=yw8d5n1Xu7Q"
            className="video"
            controls
          />
        </div>
        <div className="goc__title-container2">
          <h2>
            Why are you <span className="gradient__text">Stuck</span> ?
          </h2>
          <p className="tag">
            Correct me if I’m wrong, but if you’re reading this, you’re not
            where you want to be.
          </p>
          <div className="goc__ul">
            <ul className="goc__ul">
              <li className="goc__list-link">
                <p className="list-p">01</p>
                <p>
                  you hate your job and are looking for a higher paying less
                  stressful job
                </p>
              </li>
              <li className="goc__list-link">
                <p className="list-p">02</p>
                <p>
                  you want to be a software engineer but you’re a total beginner
                  and looking the best program for the money
                </p>
              </li>
              <li className="goc__list-link">
                <p className="list-p">03</p>
                <p>
                  you don’t know what to study to bridge the gap between where
                  you are and how to get a job offer
                </p>
              </li>
              <li className="goc__list-link">
                <p className="list-p">04</p>
                <p>you apply to hundreds of jobs and never hear back</p>
              </li>
              <li className="goc__list-link">
                <p className="list-p">05</p>
                <p>
                  you don't have the discipline to code consistently or teach
                  yourself on your own
                </p>
              </li>
              <li className="goc__list-link">
                <p className="list-p">06</p>
                <p>you're bored by the work you do and the tech you use</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="goc__title-container3">
          <h2>You want too..</h2>
          <ul className="goc__ul">
            <li className="goc__list-link-2">
              <p className="list-p-2 gradient__text">01</p>
              <p className="goc__p-2">Get hired as a developer</p>
            </li>
            <li className="goc__list-link-2">
              <p className="list-p-2 gradient__text">02</p>
              <p className="goc__p-2">Work remotely and skip the commute</p>
            </li>
            <li className="goc__list-link-2">
              <p className="list-p-2 gradient__text">03</p>
              <p className="goc__p-2">
                Start freelancing and working whenever you want
              </p>
            </li>
            <li className="goc__list-link-2">
              <p className="list-p-2 gradient__text">04</p>
              <p className="goc__p-2">Build your own apps</p>
            </li>
            <li className="goc__list-link-2">
              <p className="list-p-2 gradient__text">05</p>
              <p className="goc__p-2">Start a SaaS business</p>
            </li>
            <li className="goc__list-link-2">
              <p className="list-p-2 gradient__text">06</p>
              <p className="goc__p-2">
                you're bored by the work you do and the tech you use
              </p>
            </li>
          </ul>

          <div className="goc__info-container">
            <div className="goc__title-2">
              <h1>
                I've been <span className="gradient__text">exactly </span> where
                you are
              </h1>
            </div>
            <p>
              {" "}
              <span className="gradient__text">I’m</span> Jack Tran
              <span className="gradient__text">, founder & CEO of</span> Guild
              of Coders
            </p>
            <div>
              <p>
                As head of a community of tech industry mentors and students who
                desire to develop their skills and learn the tools necessary to
                secure lucrative employment opportunities in software
                development.
              </p>
            </div>
            <div>
              <p>
                My family and I grew up in a poor refugee family escaping
                Communism Vietnam. As a kid I worked making a few dollars a day
                as a farm worker picking raspberries. My first real job was in
                the US Navy where I cleaned toilets and urinals. I started to
                learn how to code in Mechanical Engineering courses learning
                Matlab and decided to change majors to Computer Science when I
                noticed I enjoyed coding and helping others learn how to code.
              </p>
            </div>
            <div>
              <p>
                After graduating from University and a bootcamp myself, I
                started working in Tech. After learning my teammates with only 1
                year of experience were making $60k more than me, I decided to
                jump hop every 2 years and made it my life goal to optimize my
                career earnings. I was able to grow my income from $100k to over
                $230k and received more than 20 job offers in the span of 7
                years,
              </p>
            </div>
            <div>
              <p>
                I’ve helped colleagues and friends get job offers from top tech
                companies and I'd like to do the same for you — I want you to
                become a Software Engineer, learn to code with confidence, and
                pursue the high-paying remote career you've always wanted.{" "}
                <span className="gradient__text">See you inside!</span>
              </p>
            </div>
          </div>

          <div className="goc__info-container">
            <div className="goc__title-2">
              <h1>
                <span className="gradient__text">Practice.</span>{" "}
                <span className="gradient__text">Experience.</span>{" "}
                <span className="gradient__text">Interview.</span>
              </h1>
            </div>
            <div>
              <p>
                What I noticed missing from other programs was the total package
                approach to getting students results. Boot Camp graduates still
                needed help from me to get job offers because boot camps didn’t
                cover interviewing enough. People would come to me to help them
                prepare for boot camps because they didn’t pass the entrance
                tests and interviews.
              </p>
            </div>
            <div>
              <p>
                Other programs like University or “on-rails” courses gave little
                feedback to students and provided little real world experience
                with projects.
              </p>
            </div>
            <div>
              <p>
                I created Tech Accelerator to save you years of struggle and
                frustration. If you want to condense years of learning into
                months, code with confidence, create invaluable connections and
                get a high-paying job, this is for you.
              </p>
            </div>

            <div className="button">
              <button>
                <a href="https://thangtran178782.typeform.com/to/Gns9U4EN">
                  Apply Now
                </a>{" "}
              </button>
            </div>
          </div>

          <div className="goc__title-2">
            <h1>
              <span className="gradient__text">How it Works</span>
            </h1>
          </div>
          <div>
            <p>
              The Tech Acceleator requires about 10 to 20 hours per week. It’s
              flexible and remote. There’s no need to quit your job. Learn on
              your own schedule, right from the comfort of your home. Combined
              with hands-on experience, on-demand learning, and personalised
              support, you get education that truly works. We believe in our
              program. If you don't land a software engineering job within 6
              months of graduating, we'll give you a full refund.
            </p>
          </div>
          <div>
            <h3>Learn the same Way Software Engineers Work</h3>
            <p>
              The program is designed to replicate working as a Software
              Engineer from day 1. Including coding on an IDE instead of a
              browser, working with Github, and
            </p>
          </div>
          <div>
            <h3>Real World Projects</h3>
            <p>
              Work on creating real world novel projects that are published and
              are viable products. You work as if you’re a founder of a company
              instead of a student working on a pet project.
            </p>
          </div>
          <div>
            <h3>Getting unstuck</h3>
            <p>
              The most common issue most developers face is getting stuck on a
              problem; for hours, days, or even weeks. It’s frustrating. It’s
              the reason many abandoned coding. The reason many gave up on their
              dream job.
              <div className="you">
                <p>
                  That doesn’t happen at{" "}
                  <span className="gradient__text">Tech Accelerator.</span>
                </p>
              </div>
            </p>
          </div>
          <div>
            <h3>Pull request reviews & feedback</h3>
            <p>
              The most important part of the monthly projects are code reviews.
              Almost no developers are lucky enough to have a senior developer
              review their code and give feedback.
              <div className="you">
                <p>
                  Feedback is crucial for evolving from a junior to a senior
                  developer. Mentors will refactor your code and optimize it.
                  Over time, you’ll start to think and code like a senior
                  developer.
                </p>
              </div>
            </p>
          </div>
          <div>
            <h3>1-on-1 Mentor sessions</h3>
            <p>
              Unlike many boot camps, we only take a small number of students.
              This way, our mentors can give you special attention, immediate
              direction, and feedback to make you succeed
              <div className="you">
                <p>
                  You'll have weekly 1-on-1 live personal calls with a mentor
                  who works in the industry to ensure you progress rapidly. The
                  mentors will help fix your weaknesses, so you improve quickly.
                  Your mentor will help you stay on track as you tackle your
                  projects and career goals.
                </p>
              </div>
            </p>
          </div>
          <div>
            <h3>Algorithm and Data Structures</h3>
            <p>
              You’ll complete algorithm exercises weekly with our custom
              testing. Algorithm exercises demonstrate your logic and
              problem-solving skills, which are crucial for web development.
              <div className="you">
                <p>
                  Companies require you to solve algorithm exercises in
                  interviews, so knowing algorithms is necessary for landing
                  high-paying software engineering jobs.
                </p>
              </div>
            </p>
          </div>
          <div>
            <h3>Mock interviews and Technical Interview Strategies</h3>
            <p>
              In our mock interviews, you’ll have a chance to practice your soft
              skills. You'll get feedback, improve, and learn to position
              yourself as the best person for the role. With enough practice,
              you’ll be confident in interviews.
              <br />
              <div className="you">
                <p>
                  You’ll also learn the secrets to tackling the technical
                  interview with a step by step process.
                </p>
              </div>
            </p>
          </div>
          <div>
            <h3>Resume & online profiles</h3>
            <p>
              Job search is intimidating and seems hopeless, but it doesn’t have
              to be. We’ll make sure of that. Strong resumes and online profiles
              are the key to success here, and you’ll stand out with our guides
              and checklists. You will multiply your profile views on LinkedIn
              with our frameworks and tactics. We’ll teach you how to build a
              powerful and unique Github portfolio and profile.
            </p>
          </div>
          <div>
            <h3>Webinars and Q&A's with Jack Tran</h3>
            <p>
              Jack will explain the most important topics in Software
              Engineering. What’s new in Software Engineering, how to use
              essential tools, how to improve your productivity as a developer
              and accelerate your career. After that, he will hold a live Q&A
              where you can ask him questions.
            </p>
          </div>
          </div>
            <Test />
          <div className="goc__title-container3">
          <div className="button-sucess">
            <button>
              {" "}
              <a href="https://thangtran178782.typeform.com/to/Gns9U4EN">
                Apply Now and Become a Success Story
              </a>
            </button>
        </div>
        </div>
        <div className="goc__master">
          <div>
            <h2>
              <span className="gradient__text">
                Master The Most In-Demand Tech Stack
              </span>
            </h2>
          </div>
          <p>
            Form Backend, Full Stack, and Data Software Engineering and learn
            the theory necessary to ace even Google difficult coding questions.
            <div className="you">
              <p>
                During the Tech Accelerator, you’ll learn the most in-demand
                technologies, such as Python, SQL, AWS, Flask and more! The
                program’s flexibility will allow you to choose which
                technologies you use in your projects..
              </p>
            </div>
            <div className="goc__included">
              <h1>
                <span className="gradient__text">
                  What's Included in the Tech Accelerator?
                </span>
              </h1>
            </div>
            <div className="included-div">
              <p>
                Over a 100 hours of Video and Written Lessons along with
                Homework for
              </p>
              <ul>
                <li>Python</li>
                <li>SQL</li>
                <li>Data Structures and Algorithms</li>
                <li>Project Management</li>
                <li>Web Development</li>
                <li>Cloud Technologies</li>
                <li>Interview Prep</li>
                <li>Job Search</li>
              </ul>
              <p>Group Sessions</p>
              <br />
              <p>1 on 1 Sessions with Mentor</p>
              <br />
              <p>Mock Interviews</p>
            </div>
          </p>
          <div className="goc__quiz-container">
            <div className="quiz">
              <h1>
                Still not sure if the
                <span className="gradient__text"> Tech Accelerator </span> is
                Right for You?
              </h1>
            </div>
            <div>
              <p>
                Hey, we all have doubts, whether about what we invest in or
                whether we are ready and capable to put new knowledge and skills
                to work to create beautiful outcomes.
              </p>
              <br />
              <p>
                Take the quiz, answer a few questions about your skills. We'll
                invite you to book a call with our team to discuss whether this
                is the right fit for you.
              </p>
              <div className="goc__title-button">
                <button>
                  <a
                    href="https://thangtran178782.typeform.com/to/Gns9U4EN"
                  >Take The Quiz</a>
                </button>
              </div>
            </div>
          </div>
        </div>
        <Question />
        <Footer />
      </section>
    </>
  );
};

export default CoursePage;
