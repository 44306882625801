import React from "react";
import "./header.css";
import thang from "../../assets/thang.jpeg";


const Header = () => {
  return (

    <div className="goc__header section__padding" id="home">
      <div className="goc__header-content ">
        <h1 className="gradient__text">
          Start your Software Engineering Career with Project-Based Coaching and
          Coding Bootcamp
        </h1>

        <div className="goc__header-content__input">
          <button type="button"> <a href="/#/course">Get Started</a></button>
        </div>
    </div>
        <div className="goc__header-image">
          <img src={thang} alt="thang-Tran" />
        </div>
      </div>
  );
};

export default Header;
