import React from "react";
import "./techacc.css";

const TechAcc = () => {
  return (
    
    <div className="goc__techs section__margin">
      <h4>
        <span className="gradient__text">GUILD OF CODERS IS PROUD TO
        INTRODUCE</span>
      </h4>
      <h1>THE TECH <span className="gradient__text">ACCELERATOR</span></h1>
      <p>
        Build your competency to prepare you for Software Engineering work
        Showcase your expertise to employers with exciting projects where you'll
        build real-world applications. Ace the technical interview so you can
        land multiple high salary offers
      </p>
      <p>
        Stop Paying 16k-18k for a rigid course that expects you to know how to
        code before you start and expects you to take a separate interview prep
        program afterwards.
      </p>
      <button > <a href="/#/course">Get Started</a> </button>
    </div>
 
  );
};

export default TechAcc;
